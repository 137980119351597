import React, { useState, useEffect, useImperativeHandle } from "react";
import { Form, Row, Col } from "react-bootstrap";
import ControlledDropDownV2 from "@components/Forms/ControlledDropDownV2";
import { useNavigate } from "react-router";
import { Governance, OptionType } from "../types/Governance";
import {
  GetProjectByIdApiResponse,
  V1AccountResource,
  V1PhaseResource,
  V1ProjectPhaseResource,
  V1ResourceRateResource,
  V1ResourceResource,
  useCreateProjectGovernanceMutation,
  useListServiceCategorysForAccountQuery,
  useUpdateProjectGovernanceMutation,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import {
  capitalizeFirstLetter,
  findResourceByNameAndReturnPromise,
  handleAlert,
  renderResourceOptions,
  snakeToText,
} from "@utils/helperFunctions";
import useResourceRates from "./api/useResourceRates";
import useOauth from "@utils/customHooks/useOauth";
import { FormNumberFieldV2 } from "@components/FormsV2/FormNumberFieldV2";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import RequiredReactSelect from "@components/FormsV2/RequiredReactSelect/RequiredReactSelect";
import { TextField } from "@components/FormsV2";
import ToggleOnOff from "@components/Buttons/ToggleOnOff/ToggleOnOff";
import { Select2V2 } from "@components/FormsV2";

interface Props {
  governanceProp?: Governance;
  project_id: number;
  account: {
    data: V1AccountResource;
    included: V1PhaseResource[] | V1ResourceResource[] | [];
  };
  permission: string;
  project: GetProjectByIdApiResponse;
  projectPhases: V1ProjectPhaseResource[];
}

const GovernanceForm = React.forwardRef(
  (
    {
      governanceProp,
      project_id,
      account,
      permission,
      project,
      projectPhases,
    }: Props,
    ref
  ) => {
    // Redux / ENV
    const { accountSlug } = useSelector((state: RootState) => state.slug);
    const [authorizationCode] = useOauth();
    const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

    // Get Service Categories
    const {
      data: lobs,
      error: lobsError,
      isLoading: lobsLoading,
      refetch: lobsRefetch,
    } = useListServiceCategorysForAccountQuery({ slug: accountSlug });

    // Resources
    const projectResources = project?.included?.filter(
      (item) =>
        item.type === "project-resources" && item?.attributes?.["active"]
    );
    const resources = account?.included?.filter(
      (item) => item.type === "resources" && item?.attributes?.["active"]
    );
    const resourcesFromResourceRates =
      account?.data?.attributes?.settings?.["project_resource_rates"] || false;
    const rateTableId =
      project?.included
        ?.filter(
          (item) => item.type === "rate-tables" && item?.attributes?.["active"]
        )[0]
        .id?.toString() || "";

    // Get Resource Rates
    const {
      resourceRates,
      resourceRatesError,
      resourceRatesLoading,
      pageMeta,
      resourceRatesRefetch,
    } = useResourceRates({
      resources,
      filter: { "rate-table": rateTableId },
    });

    const allocationMethods = [
      "prorate_phases_by_effort",
      "select_phase",
      "select_category",
      "project_management_phase",
    ];

    const filterTypes: OptionType[] = [
      { value: "all_services", label: "All Services" },
      { value: "filter_by_phase", label: "Filter by Phase" },
      { value: "filter_by_lob", label: "Filter by Category" },
    ];

    // Location States / React Router
    const location = window.location.href;
    const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
    var locationArr = location.split("/");
    var id = locationArr[locationArr.length - 1];
    let navigate = useNavigate();

    // Field States
    const [phase, setPhase] = useState(
      governanceProp?.phase || {
        label: "Select Phase",
        value: 0,
      }
    );

    const [resource, setResource] = useState<OptionType>(
      governanceProp?.resource || { label: "Select Resource", value: 0 }
    );

    const [filteredResources, setFilteredResources] = useState(
      resourcesFromResourceRates ? resourceRates : resources
    );
    const [description, setDescription] = useState(
      governanceProp?.description || ""
    );
    const [hours, setHours] = useState(governanceProp?.hours || 0);
    const [percentage, setPercentage] = useState(
      governanceProp?.rate ? (governanceProp?.rate * 100).toFixed(2) : 0
    );
    const [calculationType, setCalculationType] = useState(
      governanceProp?.calculationType || "fixed_hours"
    );
    const paymentInfo = project?.data?.attributes?.["payment-info"] || "hours";
    const [rateType, setRateType] = useState(
      paymentInfo["rate-type"] !== "hours"
        ? capitalizeFirstLetter(paymentInfo["rate-type"]) + "s"
        : capitalizeFirstLetter(paymentInfo["rate-type"])
    );
    const [filterType, setFilterType] = useState(
      governanceProp?.filterType || "all_services"
    );
    const [filterId, setFilterId] = useState(governanceProp?.filterId || null);
    const [assignEffortToService, setAssignEffortToService] = useState(
      governanceProp?.assignEffortToService || false
    );
    const [allocationMethod, setAllocationMethod] = useState(
      governanceProp?.allocationMethod || "prorate_phases_by_effort"
    );
    const [selectedFilterType, setSelectedFilterType] = useState(
      filterTypes.find(
        (filterType) => filterType.value == governanceProp?.filterType
      ) || filterTypes[0]
    );

    // Mutations
    const [createProjectGovernance] = useCreateProjectGovernanceMutation();
    const [updateProjectGovernance] = useUpdateProjectGovernanceMutation();

    useEffect(() => {
      if (resourcesFromResourceRates && !resourceRatesLoading) {
        setFilteredResources(resourceRates);
      }
    }, [resourceRatesLoading]);

    useImperativeHandle(ref, () => ({
      saveGovernance,
    }));

    // Alert States
    const [errorMessages, setErrorMessages] = useState<string | string[]>(
      "Something went wrong! Your changes could not be saved at this time."
    );
    const [showFailAlert, setShowFailAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState(
      "Project Governance saved successfully!"
    );
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [resourceInvalid, setResourceInvalid] = useState(false);
    const [phaseForAllocationInvalid, setPhaseForAllocationInvalid] =
      useState(false);
    const [categoryForAllocationInvalid, setCategoryForAllocationInvalid] =
      useState(false);
    const [categoryFilterInvalid, setCategoryFilterInvalid] = useState(false);
    const [phaseFilterInvalid, setPhaseFilterInvalid] = useState(false);

    // Hidden dropdown states
    const phaseOptions: any = (projectPhases || []).map((phase) => {
      return {
        label: phase?.attributes?.["name"] || "",
        value: phase?.id?.toString() || "",
      };
    });

    const lobOptions: any = (lobs?.data || []).map((lob) => {
      return {
        label:
          lob?.attributes?.["nested-name"] || lob?.attributes?.["name"] || "",
        value: lob?.id?.toString() || "",
      };
    });

    const [selectedPhaseFilter, setSelectedPhaseFilter] = useState<OptionType>(
      governanceProp?.filterId
        ? phaseOptions.find((phase) => phase.value == governanceProp?.filterId)
        : {
            label: "Select Phase",
            value: 0,
          }
    );
    const [selectedCategoryFilter, setSelectedCategoryFilter] =
      useState<OptionType>(
        governanceProp?.filterId
          ? lobOptions.find((lob) => lob.value == governanceProp?.filterId)
          : {
              label: "Select Category",
              value: 0,
            }
      );
    const [selectedPhaseForAllocation, setSelectedPhaseForAllocation] =
      useState<OptionType>(
        governanceProp?.phase || { label: "Select Phase", value: 0 }
      );

    const [selectedCategoryForAllocation, setSelectedCategoryForAllocation] =
      useState<OptionType>(
        governanceProp?.lob || { label: "Select Category", value: 0 }
      );

    const clearForm = () => {
      setDescription("");
      setPhase({ label: "Select Phase", value: 0 });
      setResource({ label: "Select Resource", value: 0 });
      setHours(0);
      setPercentage(0);
      setCalculationType("fixed_hours");
      setAllocationMethod("prorate_phases_by_effort");
      setSelectedCategoryForAllocation({ label: "Select Category", value: 0 });
      setSelectedPhaseForAllocation({ label: "Select Phase", value: 0 });
      setSelectedCategoryFilter({ label: "Select Category", value: 0 });
      setSelectedPhaseFilter({ label: "Select Phase", value: 0 });
      setSelectedFilterType(filterTypes[0]);
      setFilterId(null);
      setFilterType("all_services");
      setAssignEffortToService(false);
    };


    const getPhase = () => {
      if (selectedPhaseForAllocation.value !== 0) {
        return {
          data: {
            type: "project-phases",
            id: selectedPhaseForAllocation.value,
          },
        };
      }
    };

    const getServiceCategory = () => {
      if (selectedCategoryForAllocation.value !== 0) {
        return {
          data: {
            type: "service-categories",
            id: selectedCategoryForAllocation.value,
          },
        };
      }
    };

    const validateForm = () => {
      let isValid = true;
      if (resource.value == 0) {
        setResourceInvalid(true);
        isValid = false;
      }
      if (
        filterType === "filter_by_lob" &&
        selectedCategoryFilter.value === 0
      ) {
        setCategoryFilterInvalid(true);
        isValid = false;
      }
      if (filterType === "filter_by_phase" && selectedPhaseFilter.value === 0) {
        setPhaseFilterInvalid(true);
        isValid = false;
      }
      if (
        allocationMethod === "select_category" &&
        selectedCategoryForAllocation.value === 0
      ) {
        setCategoryForAllocationInvalid(true);
        isValid = false;
      }
      if (
        allocationMethod === "select_phase" &&
        selectedPhaseForAllocation.value === 0
      ) {
        setPhaseForAllocationInvalid(true);
        isValid = false;
      }
      return isValid;
    };

    const getProjectResourceRelationship = () => {
      console.log('helllooooo');
      const resourceType = resource.value
        .toString()
        .split("-")
        .slice(0, -1)
        .join("-");
      const resourceId = resource.value.toString().split("-").at(-1);
      if (resource.value == 0) return undefined;
      return {
        data: {
          type: "project-resources",
          id:
            resourceType === "project-resources" ? resourceId : resource.value,
        },
      };
    };

    const saveGovernance = (saveAndAddClicked) => {
      const formIsValid = validateForm();
      if (!formIsValid) {
        return;
      }
      let governanceData: any = {
        type: "project-governances",
        id: id,
        attributes: {
          description: description,
          rate: (Number(percentage) / 100).toFixed(2),
          "fixed-hours": hours,
          "calculation-type": calculationType,
          "allocation-method": allocationMethod,
          "assign-effort-to-service": assignEffortToService,
          "filter-type": filterType,
          "filter-id": filterId,
        },
        relationships: {
          project: { data: { type: "projects", id: project_id } },
          "project-phase": getPhase(),
          "service-category": getServiceCategory(),
          "project-resource": getProjectResourceRelationship()
        },
      };

      if (lastWordInLocation !== "new") {
        updateProjectGovernance({
          slug: accountSlug,
          id: Number(governanceData.id),
          body: { data: governanceData },
        })
          .unwrap()
          .then((response) => {
            if (response?.data?.id) {
              navigate(`/projects/${project_id}/project_governances`, {
                state: { edit: true },
              });
            }
          })
          .catch((err) => {
            let msg = err?.data?.errors?.[0]?.detail;
            setErrorMessages(
              msg
                ? msg
                : "Something went wrong. Your changes could not be saved at this time."
            );
            setShowFailAlert(true);
          });
      } else {
        delete governanceData.id;
        createProjectGovernance({
          slug: accountSlug,
          body: { data: governanceData },
        })
          .unwrap()
          .then((response) => {
            if (response?.data?.id) {
              if (saveAndAddClicked) {
                clearForm();
                setSuccessMessage("Project Governance created successfully!");
                setShowSuccessAlert(true);
              } else {
                navigate(`/projects/${project_id}/project_governances`, {
                  state: { new: true },
                });
              }
            }
          })
          .catch((err) => {
            let msg = err?.data?.errors?.[0]?.detail;
            setErrorMessages(
              msg
                ? msg
                : "Something went wrong. Your changes could not be saved at this time."
            );
            setShowFailAlert(true);
          });
      }
    };

    if (!account || !lobs || lobsLoading) {
      return <SmallSpinner />;
    }

    return (
      <Form style={{ marginLeft: "16px" }} className="governanceForm">
        {handleAlert(
          showFailAlert,
          errorMessages,
          setShowFailAlert,
          "warning",
          ToastAlert
        )}
        {handleAlert(
          showSuccessAlert,
          successMessage,
          setShowSuccessAlert,
          "success",
          ToastAlert
        )}
        <section>
          <div className="instructionalText bold">About Governance</div>
          <Row>
            <Col sm={8}>
              <TextField
                label="Description *"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id="governanceDescription"
                placeholder="Enter Description"
              />
            </Col>
            <Col sm={4}>
              <RequiredReactSelect
                label="Resource *"
                value={resource}
                required={true}
                isInvalid={resourceInvalid && resource.value == 0}
                onChange={(e) => setResource(e as OptionType)}
                options={renderResourceOptions(
                  projectResources,
                  filteredResources,
                  lobs?.data
                )}
              />
            </Col>
          </Row>
        </section>
        <hr style={{ marginRight: "10px" }} />
        <section>
          <div className="instructionalText bold">Governance Effort</div>
          <Row>
            <Col sm={4}>
              <Form.Check
                type="radio"
                id="fixedHoursCheck"
                label={`Fixed Number of ${rateType}`}
                checked={calculationType === "fixed_hours"}
                value="fixed_hours"
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setCalculationType(
                      (e.target.value as Governance["calculationType"]) ||
                        "fixed_hours"
                    );
                    setFilterId(null);
                    setFilterType("all_services");
                    setCategoryFilterInvalid(false);
                    setPhaseFilterInvalid(false);
                    setSelectedFilterType({
                      value: "all_services",
                      label: "All Services",
                    });
                    setSelectedPhaseFilter({ label: "Select Phase", value: 0 });
                    setSelectedCategoryFilter({
                      label: "Select Category",
                      value: 0,
                    });
                  }
                }}
              />
              <Form.Check
                type="radio"
                id="percentOfTotalHoursCheck"
                label={`Percent of Total ${rateType}`}
                checked={calculationType === "percent_of_total"}
                value="percent_of_total"
                onChange={(e) => {
                  if (e.target.checked === true) {
                    if (lastWordInLocation === "edit") {
                      setHours(governanceProp?.hours || 0);
                    }
                    setCalculationType(
                      (e.target.value as Governance["calculationType"]) ||
                        "fixed_hours"
                    );
                  }
                }}
              />
            </Col>
            <Col sm={4}>
              <FormNumberFieldV2
                label={
                  calculationType == "percent_of_total" ? "Percentage" : "Hours"
                }
                value={
                  calculationType == "percent_of_total"
                    ? Number(percentage).toFixed()
                    : hours
                }
                onChange={(e) => {
                  if (calculationType == "percent_of_total") {
                    setPercentage(Number(e.target.value));
                  } else {
                    setHours(Number(e.target.value));
                  }
                }}
                placeholder={
                  calculationType == "percent_of_total"
                    ? "Enter a percenatage"
                    : "Enter hours"
                }
              />
            </Col>
          </Row>
          {calculationType !== "fixed_hours" ? (
            <Row>
              <Col sm={4}>
                <Select2V2
                  label="Which Services Impact Governance"
                  value={selectedFilterType}
                  isDisabled={false}
                  options={filterTypes}
                  onChange={(e) => {
                    setSelectedFilterType(e as OptionType);
                    setFilterType(e.value);
                    setFilterId(null);
                    setCategoryFilterInvalid(false);
                    setPhaseFilterInvalid(false);
                    setSelectedPhaseFilter({ label: "Select Phase", value: 0 });
                    setSelectedCategoryFilter({
                      label: "Select Category",
                      value: 0,
                    });
                  }}
                />
              </Col>
              {filterType !== "all_services" ? (
                <Col sm={4}>
                  <RequiredReactSelect
                    label={
                      filterType === "filter_by_phase"
                        ? "Pick Phase"
                        : "Pick Category"
                    }
                    value={
                      filterType === "filter_by_phase"
                        ? selectedPhaseFilter
                        : selectedCategoryFilter
                    }
                    isDisabled={false}
                    options={
                      filterType === "filter_by_phase"
                        ? phaseOptions
                        : lobOptions
                    }
                    onChange={(e) => {
                      let event = e as OptionType;
                      setFilterId(event.value.toString());
                      if (filterType === "filter_by_phase") {
                        setSelectedPhaseFilter(event);
                      }
                      if (filterType === "filter_by_lob") {
                        setSelectedCategoryFilter(event);
                      }
                      setPhaseFilterInvalid(false);
                      setCategoryFilterInvalid(false);
                    }}
                    required={
                      filterType === "filter_by_phase" ||
                      filterType === "filter_by_lob"
                    }
                    isInvalid={
                      (filterType === "filter_by_phase" &&
                        phaseFilterInvalid) ||
                      (filterType === "filter_by_lob" && categoryFilterInvalid)
                    }
                  />
                </Col>
              ) : null}
            </Row>
          ) : null}
        </section>
        <hr style={{ marginRight: "10px" }} />
        <section>
          <div className="instructionalText bold">Assign to Project</div>
          <Row>
            <Col sm={4}>
              <ControlledDropDownV2
                label="Allocate Governance By"
                value={allocationMethod}
                options={
                  allocationMethods.map((method) => {
                    return (
                      <option value={method} key={method}>
                        {snakeToText(method)}
                      </option>
                    );
                  }) || []
                }
                onChange={(e) => {
                  setAllocationMethod(
                    (e.target.value as Governance["allocationMethod"]) ||
                      "prorate_phases_by_effort"
                  );
                  if (allocationMethod !== "select_category") {
                    setSelectedCategoryForAllocation({
                      label: "Select Category",
                      value: 0,
                    });
                  }
                  if (allocationMethod !== "select_phase") {
                    setSelectedPhaseForAllocation({
                      label: "Select Phase",
                      value: 0,
                    });
                  }
                  setCategoryForAllocationInvalid(false);
                  setPhaseForAllocationInvalid(false);
                }}
                required={false}
                readOnly={false}
                disabled={false}
              />
            </Col>
            {allocationMethod !== "prorate_phases_by_effort" &&
            allocationMethod !== "project_management_phase" ? (
              <Col sm={4}>
                <RequiredReactSelect
                  label={
                    allocationMethod === "select_phase"
                      ? "Pick Phase"
                      : allocationMethod === "select_category"
                      ? "Pick Category"
                      : ""
                  }
                  value={
                    allocationMethod === "select_phase"
                      ? selectedPhaseForAllocation
                      : allocationMethod === "select_category"
                      ? selectedCategoryForAllocation
                      : { label: "Select", value: 0 }
                  }
                  isDisabled={false}
                  options={
                    allocationMethod === "select_phase"
                      ? phaseOptions
                      : allocationMethod === "select_category"
                      ? lobOptions
                      : []
                  }
                  onChange={(e) => {
                    if (allocationMethod === "select_phase") {
                      setSelectedPhaseForAllocation(e as OptionType);
                    } else if (allocationMethod === "select_category") {
                      setSelectedCategoryForAllocation(e as OptionType);
                    }
                    setPhaseForAllocationInvalid(false);
                    setCategoryForAllocationInvalid(false);
                  }}
                  required={
                    allocationMethod === "select_phase" ||
                    allocationMethod === "select_category"
                  }
                  isInvalid={
                    (allocationMethod === "select_phase" &&
                      phaseForAllocationInvalid) ||
                    (allocationMethod === "select_category" &&
                      categoryForAllocationInvalid)
                  }
                />
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col className="labelLike" style={{ marginTop: "20px" }}>
              Assign Effort To Service?
            </Col>
          </Row>
          <Row>
            <Col className="instructionalText">
              When checked, the effort and pricing from this governance will be
              added to the services on the project rather than be reported
              separately.
              {assignEffortToService}
            </Col>
          </Row>
          <Row>
            <Col>
              <ToggleOnOff
                initialValue={assignEffortToService}
                onToggle={(e) => {
                  setAssignEffortToService(e);
                }}
              />
            </Col>
          </Row>
        </section>
      </Form>
    );
  }
);

export default GovernanceForm;

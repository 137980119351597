import React, { useState, useEffect } from "react";
import { useListGovernances } from "./api";
import DataTable from "@components/DataTable";
import {
  useChangeGovernancePositionByIdMutation,
  useUpdateProjectGovernanceMutation,
  useChangeProjectGovernancePositionByIdMutation,
  useDeleteProjectGovernanceMutation,
  useListServiceCategorysForAccountQuery,
  V1ProjectGovernanceResource,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { ColumnDef } from "@tanstack/react-table";
import { Card, Row, Col, Button } from "react-bootstrap";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import "./styles/style.css";
import { useLocation, useNavigate } from "react-router";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { Governance } from "../types/Governance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";

const Show = ({
  project_id,
  account,
  permission,
  project,
  projectPhases,
}): JSX.Element => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const [selectedGovernanceIds, setSelectedGovernanceIds] = useState<string[]>(
    []
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessages, setErrorMessages] = useState<string | string[]>(
    "Something went wrong! Your changes could not be saved at this time."
  );
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    "Project Governance saved successfully!"
  );
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [reloading, setReloading] = useState(false);

  // Bulk Delete States
  const [deleteGovernance] = useDeleteProjectGovernanceMutation();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteQueue, setDeleteQueue] = useState<string[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [updateProjectGovernancePosition] =
    useChangeProjectGovernancePositionByIdMutation();

  const [updateProjectGovernance] = useUpdateProjectGovernanceMutation();

  const [governances, setGovernances] = useState<Governance[]>([]);

  const {
    data: lobs,
    isLoading: lobsLoading,
    isError: lobsErr,
  } = useListServiceCategorysForAccountQuery({ slug: accountSlug });

  const {
    data: governanceData,
    governancesError,
    governancesLoading,
    governancesFetching,
    pageMeta,
    refetch,
  } = useListGovernances({
    page: {
      number: pageNumber,
      size: pageSize,
    },
    include: [
      "resource",
      "project-phase",
      "project-resource",
      "project",
      "service-category",
    ],
    lobs: lobs && lobs.data && !lobsLoading ? lobs.data : [],
    filter: { active: true, project: project_id },
    projectPhases: projectPhases || [],
  });

  useEffect(() => {
    window.history.replaceState({}, "");
    refetch();
    if (location?.state?.edit) {
      setSuccessMessage("Project Governance updated successfully!");
      setShowSuccessAlert(true);
    }
    if (location?.state?.new) {
      setSuccessMessage("Project Governance created successfully!");
      setShowSuccessAlert(true);
    }
  }, []);

  const onUpdateGovernances = (governances) => {
    setGovernances(governances);
  };

  useEffect(() => {
    if (!governancesLoading && !governancesFetching) {
      setGovernances(governanceData);
    }
  }, [governancesLoading, governancesFetching]);

  useEffect(() => {
    const updateGovernances = async () => {
      const changedGovernances = governances.filter((g) => g?.isChanged);
      if (changedGovernances.length) {
        try {
          for (const governance of changedGovernances) {
            let data: V1ProjectGovernanceResource = {
              id: Number(governance.id),
              type: "project-governances",
              attributes: {
                position: governance.position,
              },
            };

            await updateProjectGovernance({
              slug: accountSlug,
              id: data.id!,
              body: { data },
            });
          }
          refetch();
        } catch (error) {
          setErrorMessages("Something went wrong updating governances");
          setShowFailAlert(true);
        }
      }
    };

    updateGovernances();
  }, [governances]);

  const handleDelete = () => {
    setDeleteQueue([...selectedGovernanceIds]);
    setShowConfirmationModal(true);
  };

  const confirmDelete = () => {
    setIsDeleting(true);
    if (deleteQueue.length === 0) return;

    const promises: any = [];
    deleteQueue.forEach((governanceIdToDelete) => {
      promises.push(
        deleteGovernance({
          slug: accountSlug,
          id: parseInt(governanceIdToDelete),
        })
      );
    });

    Promise.all(promises)
      .then((res) => {
        setSuccessMessage(
          `Project Governance${
            deleteQueue.length > 1 ? "s" : ""
          } deleted successfully!`
        );
        setShowSuccessAlert(true);
        setIsDeleting(false);
        setShowConfirmationModal(false);
        setSelectedGovernanceIds([]);
        setDeleteQueue([]);
        refetch();
      })
      .catch((error) => {
        setErrorMessages(
          "One or more governances could not be deleted at this time. Please try again later."
        );
        setShowFailAlert(true);
      });
  };

  const handleConfirm = () => {
    confirmDelete();
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
    setDeleteQueue([]);
  };

  const getConfirmationMessage = () => {
    return (
      <>
        <div
          style={{
            color: "#1C2655",
            fontSize: "14px",
          }}
        >
          {isDeleting ? (
            <SmallSpinner />
          ) : (
            <span style={{ fontWeight: "700" }}>{`You are about to delete ${
              selectedGovernanceIds.length
            } governance item${
              selectedGovernanceIds.length > 1 ? "s" : ""
            }.`}</span>
          )}
          <ul style={{ marginTop: "10px" }}>
            {selectedGovernanceIds.map((govIdToDelete) => {
              let govName = governances.find(
                (gov) => gov.id == govIdToDelete
              )?.description;
              return <li>{govName}</li>;
            })}
          </ul>
          This action cannot be undone. Do you wish to proceed?
        </div>
      </>
    );
  };

  const governanceColumns: ColumnDef<Governance>[] = [
    {
      header: "Governance",
      cell(props) {
        return (
          <div>
            {props.row.original.description}{" "}
            {props.row.original.id !== 0 && (
              <FontAwesomeIcon
                className="cursorPoint"
                icon={faPencil}
                onClick={() => {
                  navigate(
                    `/projects/${project_id}/project_governances/edit/${props.row.original.id}`,
                    {
                      state: { governance: props.row.original },
                    }
                  );
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      header: "Phase",
      cell(props) {
        return props.row.original.phaseForDisplay || "";
      },
    },
    {
      header: "Resource",
      cell(props) {
        return props.row.original.resource?.label !== "Select Resource"
          ? props.row.original.resource?.label
          : "";
      },
    },
    {
      header: "Calculate Hrs By",
      cell(props) {
        return props.row.original.calculationTypeForDisplay;
      },
    },
    {
      header: "Hours",
      cell(props) {
        return governances.length > 0
          ? Number(props.row.original.hours).toFixed(1)
          : "";
      },
    },
  ];

  if (permission === "none") {
    return (
      <div className="governancePage">
        You do not have permmission to view this page. Please contact your
        administrator for assistance.
      </div>
    );
  }

  return (
    <div className="governancePage">
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <Card>
        <Card.Header
          style={{
            backgroundColor: "#fff",
            color: "#1C2655",
            padding: "0px 16px",
          }}
        >
          <Row style={{ padding: "20px 0 0 20px", alignItems: "center" }}>
            <Col className="text24">Governance</Col>
            {permission !== "view" &&
              project?.data?.attributes?.status === "building" && (
                <Col>
                  <Button
                    style={{ marginLeft: "60px", marginRight: "14px" }}
                    className="squareGreenButton floatRight"
                    onClick={() => {
                      navigate(
                        `/projects/${project_id}/project_governances/new`,
                        {
                          state: {},
                        }
                      );
                    }}
                  >
                    Add Governance
                  </Button>
                </Col>
              )}
          </Row>
        </Card.Header>
        <hr style={{ marginLeft: "30px", marginRight: "30px" }} />
        <Card.Body style={{ marginTop: "-10px" }}>
          {selectedGovernanceIds.length > 0 ? (
            <Row>
              <Col sm={3}>
                <Button className="deleteBox" onClick={handleDelete}>
                  {selectedGovernanceIds.length} Selected{" "}
                  <span className="selectedSpan"></span>
                  <FontAwesomeIcon
                    style={{ color: "black" }}
                    icon={faTrashCan}
                  />
                </Button>
              </Col>
              <Col
                style={{ marginLeft: "-10%" }}
                className="instructionalText bold multiDeletePlaceholder"
              >
                <div>
                  Total Project Effort:&nbsp;
                  {project?.data?.attributes?.["total-effort"]}&nbsp;hrs
                </div>
              </Col>
            </Row>
          ) : (
            <div
              style={{ paddingLeft: "2rem" }}
              className="instructionalText bold multiDeletePlaceholder"
            >
              Total Project Effort:&nbsp;
              {project?.data?.attributes?.["total-effort"]}&nbsp;hrs
            </div>
          )}

          {!governances ||
          governancesLoading ||
          !lobs ||
          lobsLoading ||
          governancesFetching ? (
            <SmallSpinner />
          ) : (
            <DataTable
              classForTable="governanceTable"
              data={
                governances && governances?.length > 0
                  ? governances.sort((a, b) =>
                      a.position && b.position ? a.position - b.position : 0 - 0
                    )
                  : [
                      {
                        id: 0,
                        description: "Add governances to get started",
                      },
                    ]
              }
              setData={onUpdateGovernances}
              columns={governanceColumns}
              selectable={
                governances.length > 0 &&
                permission !== "view" &&
                project?.data?.attributes?.status === "building"
              }
              striped
              hover
              selectedIds={selectedGovernanceIds}
              setSelectedIds={setSelectedGovernanceIds}
              totalPages={pageMeta.pageCount}
              totalRows={pageMeta.recordCount}
              currentPage={pageNumber}
              setCurrentPage={setPageNumber}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
              paginationEnabled
              pageSizeEnabled
              dragAndDrop={governances.length > 1}
            />
          )}
          <ScopeStackModal
            modalTitle="Delete Governance"
            modalBody={getConfirmationMessage()}
            handleButton2Click={isDeleting ? () => null : handleConfirm}
            handleClose={handleCancel}
            show={showConfirmationModal}
            handleButton1Click={handleCancel}
            deleteModal={true}
            button1Text={"Cancel"}
            button2Text={"Delete"}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default Show;

import {
  ListQuotesForAccountApiArg,
  V1LineOfBusinessResource,
  V1VendorResource,
  useListQuotesForAccountQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { OptionType, Quote } from "../../types/Quote";
import { getFormattedQuote } from "../../quoteHelpers/quoteHelpers";

export interface HookArgs {
  page?: ListQuotesForAccountApiArg["page"];
  include?: ListQuotesForAccountApiArg["include"];
  filter?: ListQuotesForAccountApiArg["filter"];
  lobs?: V1LineOfBusinessResource[] | [];
  vendors?: V1VendorResource[] | [];
  billingFrequencies?: OptionType[];
}

const useListQuotes = ({
  page,
  include,
  filter,
  billingFrequencies,
}: HookArgs) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading, refetch } = useListQuotesForAccountQuery({
    slug: accountSlug,
    page,
    include,
    filter,
  });

  let quotes: Quote[] = [];
  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };
  let vendors = data?.included?.filter((item) => item.type === "vendors") || [];
  let lobs = data?.included?.filter(
    (item) => item.type === "lobs"
  );

  if (data?.data) {
    try {
      quotes = data.data.map((quote) => {
        if (!quote.id) {
          throw new Error("Missing quote.id");
        }

        return getFormattedQuote(quote, vendors, billingFrequencies, lobs);
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    quotes: quotes,
    quotesError: error || customError,
    quotesLoading: isLoading,
    pageMeta,
    refetch,
  };
};

export default useListQuotes;

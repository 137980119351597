import { snakeToPascalCase, splitPascalCase } from "@utils/helperFunctions";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";

export const getRate = (governance) => {
  const calculationType = governance?.attributes?.["calculation-type"];
  if (calculationType === "percent_of_total") {
    return (parseFloat(governance?.attributes?.rate) * 100).toFixed(1);
  }
};

export const getNoPhaseAlert = (type) => {
  return (
    <div className="phaseAlert">
      <FontAwesomeIcon icon={faTriangleExclamation} />
      {type} not selected
      <span>(select now)</span>
    </div>
  );
};

export const findCategoryById = (catId, lobs) => {
  let retVal = null;

  lobs.forEach((lob) => {
    if (lob.id == catId) retVal = lob;
    if (lob.categories && retVal === null) {
      lob.categories.forEach((cat) => {
        if (cat.id == catId) retVal = cat;
      });
    }
  });
  return retVal;
};

export const getRateData = (governance, phases, lobs) => {
  const calculationType = governance?.attributes?.["calculation-type"] || "";
  if (calculationType === "fixed_hours") return "Fixed Hours";

  let rate = getRate(governance) + "%";
  if (governance?.attributes?.["filter-type"] === "all_services") {
    return `${rate} of Total hours`;
  } else if (governance?.attributes?.["filter-type"] === "filter_by_phase") {
    let filterId = governance.attributes["filter-id"];
    let phase = phases.find((item) => item.id == filterId);
    if (!phase) {
      return getNoPhaseAlert("Phase");
    }
    return `${rate} of ${phase.attributes.name} hours`;
  } else if (governance.attributes["filter-type"] === "filter_by_lob") {
    let filterId = governance.attributes["filter-id"];
    let category = findCategoryById(filterId, lobs) || { attributes: "Total" };
    if (!category) {
      return getNoPhaseAlert("Category");
    }
    return `${rate} of ${category.attributes?.["nested-name"]} hours`;
  }

  return `${rate + " " + splitPascalCase(snakeToPascalCase(calculationType))}`;
};

export const getPhase = (governance, phases) => {
  let projectPhase = "";
  let allocationMethod = governance.attributes["allocation-method"];
  let phase = governance.relationships["project-phase"].data;
  if (phase && allocationMethod === "select_phase") {
    let phaseId = phase.id;
    for (let i = 0; i < phases.length; i++) {
      if (phases[i].id === phaseId) {
        projectPhase = phases[i].attributes.name;
      }
    }
    return projectPhase;
  } else {
    return splitPascalCase(snakeToPascalCase(allocationMethod));
  }
};

export const renderLobName = (lob) => {
  if (lob.attributes["has-parent?"])
    return "- " + lob.attributes["nested-name"];
  return lob.attributes.name;
};

export const renderLobOptions = (lobs) => {
  let lobsWithCategories: any[] = [];
  if (lobs !== undefined) {
    lobs.forEach((lob) => {
      if (lob && lob.attributes.active) {
        lobsWithCategories.push(lob);
        if (lob.categories) {
          lob.categories.forEach((cat) => {
            if (cat.attributes.active) lobsWithCategories.push(cat);
          });
        }
      }
    });
  }

  return lobsWithCategories.map((lob) => {
    return { label: renderLobName(lob), value: lob.id };
  });
};

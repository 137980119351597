import General from "../common/General/General";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Service, Subservice } from "../common/types";
import { formatServiceData } from "../Show/common/helpers";
import {
  useCreateServiceMutation,
  useCreateSubserviceMutation,
  V1SubserviceResource,
  V1LevelOfEffortResource,
} from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { handleAlert } from "@utils/helperFunctions";
import useGetAccount from "../../Teams/common/api/useGetAccount";
import { useListResourcesForAccount } from "../common/api";
import { Option } from "../Show/types";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";

const New = ({ permission, allProjectVariables }): JSX.Element => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { account } = useGetAccount();
  const navigate = useNavigate();
  const location = window.location.href;
  const managedService: boolean = location.includes("managed_services");
  const [createService] = useCreateServiceMutation();
  const [createSubservice] = useCreateSubserviceMutation();
  const { resources } = useListResourcesForAccount();
  const [resourceOptions, setResourceOptions] = useState<Option[]>([]);
  const [saveEnabled, setSaveEnabled] = useState<boolean>(false);
  const [service, setService] = useState<Service>({
    id: "",
    name: "",
    phase: "",
    category: "",
    resource: "",
    sku: "",
    hours: "",
    tags: [],
    teams: [],
    frequency: "",
    languages: {},
    "service-description": "",
    subservices: [],
    guidance: "",
    variables: allProjectVariables,
  });
  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleFailResponse = (msg) => {
    setLoading(false);
    setErrorMessage(msg || "Something went wrong.");
    setShowFailAlert(true);
  };

  const onSave = () => {
    setLoading(true);
    if (!account?.id) {
      handleFailResponse("");
      return;
    }
    const data = formatServiceData(service, account.id, managedService);
    delete data.id;

    createService({ slug: accountSlug, body: { data } })
      .unwrap()
      .then((res) => {
        const id = res?.data?.id;
        if (id) {
          const promises = service.subservices.map((subservice) => {
            let data: V1SubserviceResource = {
              type: "subservices",
              attributes: {
                name: subservice.name,
                "suggested-hours": subservice?.hours
                  ? Number(subservice.hours)
                  : undefined,
                quantity: subservice.quantity,
                position: subservice.position,
              },
              relationships: {
                resource: {
                  data: {
                    type: "resources",
                    id: subservice?.resource
                      ? Number(subservice.resource)
                      : Number(service.resource),
                  },
                },
                service: {
                  data: {
                    type: "services",
                    id,
                  },
                },
              },
            };
            return createSubservice({ slug: accountSlug, body: { data } });
          });

          Promise.all(promises)
            .then((res) => {
              navigate(
                `/admin/${
                  managedService ? "managed_services" : "tasks"
                }/${id}/edit`,
                {
                  state: { from: location },
                }
              );
            })
            .catch((err) => {
              handleFailResponse("");
            });
        } else {
          handleFailResponse("");
        }
      })
      .catch((error) => handleFailResponse(error?.data?.errors?.[0]?.detail));
  };

  useEffect(() => {
    if (service.name !== "" && service.category !== "") {
      if (
        (managedService && service.frequency !== "") ||
        (!managedService && service.phase !== "" && service.resource !== "")
      ) {
        setSaveEnabled(true);
      } else {
        setSaveEnabled(false);
      }
    } else {
      setSaveEnabled(false);
    }
  }, [service]);

  useEffect(() => {
    if (resources.length !== resourceOptions.length) {
      setResourceOptions(resources);
    }
  }, [resources]);

  return (
    <div className="profServicesV2">
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <div className="headerContainer">
        <div className="actionBtns">
          <Button
            className="cancelButton"
            onClick={(e) =>
              navigate(
                managedService ? "/admin/managed_services" : "/admin/tasks"
              )
            }
          >
            Cancel
          </Button>
          <h2>Add a {managedService ? "Managed" : "Professional"} Service</h2>
        </div>

        <div className={"actionBtns"}>
          <Button
            className={`button ${!saveEnabled ? "disabled" : ""}`}
            onClick={() => {
              setLoading(true);
              onSave();
            }}
            disabled={!saveEnabled || loading}
          >
            Save
          </Button>
        </div>
      </div>
      {loading ? (
        <SmallSpinner />
      ) : (
        <General
          service={service}
          setService={setService}
          isManagedService={managedService}
          permission={permission}
          editing={false}
          resourceOptions={resourceOptions}
          refetch={() => null}
          allProjectVariables={allProjectVariables}
        />
      )}
    </div>
  );
};

export default New;

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers/rootReducer";
import { Row, Col, Card, Button } from "react-bootstrap";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import ToggleOnOff from "@components/Buttons/ToggleOnOff/ToggleOnOff";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import { Subscription, AddOns } from "../../../../types";
import API from "../../../../utils/API/API";
import useOauth from "@utils/customHooks/useOauth";
import ToastAlert from "../../../../components/Alerts/ToastAlert/ToastAlert";
import { handleAlert } from "../../../../utils/helperFunctions";
import { upgradePageData } from "../../../UpgradePage/upgradePageData";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import ConfirmationModal from "@components/Modals/ConfirmationModal";
import useGetAccount from "../api/useGetAccount";
import useGetFreeTrials from "../api/useGetFreeTrials";
import { useListSubscriptionsForAccountQuery } from "@generated";

const Billing = (): JSX.Element => {
  //ENV
  const chargeBeeEnv = process.env.CHARGEBEE_ENV;
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const [authorizationCode] = useOauth();
  //Redux/API
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  const { data: account, error, isLoading } = useGetAccount(accountSlug);

  const {
    data: subscription,
    error: subscriptionError,
    isLoading: subscriptionLoading,
  } = useListSubscriptionsForAccountQuery();

  const {
    data: freeTrials,
    error: freeTrialsError,
    isLoading: freeTrialsLoading,
  } = useGetFreeTrials(accountSlug);

  //States
  const [freeTrialActive, setFreeTrialActive] = useState(false);
  const [freeTrialId, setFreeTrialId] = useState("");
  const [freeTrialAvailable, setFreeTrialAvailable] = useState(false);
  const [trialDaysRemaining, setTrialDaysRemaining] = useState(0);
  const [nextPlanUp, setNextPlanUp] = useState<any>({});
  const [currentSubscription, setCurrentSubscription] =
    useState<Subscription | null>(null);
  const [entitlements, setEntitlements] = useState(new Array());
  const [displayedEntitlements, setDisplayedEntitlements] = useState(
    new Array()
  );
  const [addOns, setAddOns] = useState<AddOns[]>([]);
  const [billingUnit, setBillingUnit] = useState<string>("");
  const [currencyUnit, setCurrencyUnit] = useState<string>("");
  const [planPrice, setPlanPrice] = useState<number>(0);
  const [showAddOnModal, setShowAddOnModal] = useState(false);
  const [changedAddOns, setChangedAddOns] = useState(new Array());
  const [showCancelTrialModal, setShowCancelTrialModal] = useState(false);

  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    "Account updated successfully!"
  );
  const [cbInstance, setCbInstance] = useState<any>(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.chargebee.com/v2/chargebee.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  }, []);

  if (
    document.readyState === "complete" &&
    window["Chargebee"] &&
    !cbInstance
  ) {
    //Create chargbee instance
    let instance = window["Chargebee"].init({ site: chargeBeeEnv });
    instance.setBusinessEntity(accountSlug);
    setCbInstance(instance);
  }

  const getPlanNameFromId = (planName) => {
    switch (planName) {
      case "free":
        return "free";
      case "ScopeStack-Essentials-Plan-USD-Monthly":
        return "Essentials";
      case "ScopeStack-Business-Plan-USD-Monthly":
        return "Business";
      case "ScopeStack-Premium-Plan-USD-Monthly":
        return "Premium";
    }
  };

  const getNextPlanUp = (currentPlan) => {
    let planIndex = upgradePageData.map((plan) => plan.id).indexOf(currentPlan);
    if (planIndex !== -1 && planIndex !== upgradePageData.length - 1) {
      setNextPlanUp(upgradePageData[planIndex + 1]);
    } else {
      setNextPlanUp({});
    }
  };

  useEffect(() => {
    if (subscription && freeTrials) {
      let entitlements = subscription?.data?.attributes?.entitlements;
      setEntitlements(entitlements || []);
      setDisplayedEntitlements(entitlements?.slice(0, 5) || []);
      setAddOns(subscription?.data?.attributes?.addons || []);
      let billingUnit =
        subscription?.data?.attributes?.["plan-revenue"]?.[
          "billing_period_unit"
        ];
      setBillingUnit(billingUnit || "");
      setCurrencyUnit(
        account && account.attributes.fieldLabels
          ? account.attributes.fieldLabels["currency_unit"]
          : ""
      );
      setPlanPrice(
        subscription?.data?.attributes?.["plan-revenue"]?.price || 0
      );
      //@ts-ignore
      setCurrentSubscription(subscription);
      const planId = subscription?.data?.attributes?.["plan-id"];
      getNextPlanUp(planId ? planId : "free");
      setFreeTrialAvailable(
        subscription?.data?.attributes?.["free-trial-available"] || false
      );

      if (freeTrials.length > 0) {
        const activeFreeTrial = freeTrials.filter(
          (item) => item.attributes.status === "active"
        )[0];
        if (activeFreeTrial) {
          setFreeTrialActive(true);
          setFreeTrialId(activeFreeTrial.attributes.trialPlanId);
          //calculate days left until trial end date
          const dateString = activeFreeTrial.attributes.endOn;

          const [year, month, day] = dateString.split("-").map(Number);
          const inputDate = new Date(year, month - 1, day);
          const currentDate = new Date();
          const timeDifference = inputDate.getTime() - currentDate.getTime();
          const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
          const daysAway =
            Math.ceil(daysDifference) > 0 ? Math.ceil(daysDifference) : 0;
          setTrialDaysRemaining(daysAway);
        }
      }
    }
  }, [
    isLoading,
    subscription,
    subscriptionError,
    authorizationCode,
    freeTrialsLoading,
  ]);

  const saveAddOns = (event) => {
    setShowAddOnModal(false);
    event.preventDefault();
    if (cbInstance) {
      cbInstance.openCheckout({
        hostedPage: async () => {
          let upgradeData = {};
          if (subscription) {
            const newAddOns = addOns
              .filter((ao) => ao.purchased)
              .map((addOn) => {
                return {
                  item_price_id: addOn.id,
                  quantity: 1,
                };
              });
            upgradeData = {
              data: {
                type: "purchase-pages",
                attributes: {
                  mode: "upgrade",
                  "subscription-items": [
                    {
                      item_price_id:
                        subscription?.data?.attributes?.["plan-id"],
                      quantity: !subscription?.data?.attributes?.["max-users"]
                        ? 1
                        : Number(
                            subscription?.data?.attributes?.["max-users"] || 0
                          ),
                    },
                    ...newAddOns,
                  ],
                },
              },
            };
          }

          let response = await API.Post(
            `${apiHost}/v1/purchase-pages`,
            upgradeData,
            authorizationCode
          );
          return response.data.data.attributes["hosted-page"];
        },
        // When the chargebee dialog has done it's magic, We can do whatever makes sense here
        success: function (hostedPageId) {},
        close: function () {
          window.location.reload();
        },
      });
    }
  };

  const getEntitlementsTableData = () => {
    if (displayedEntitlements.length === 0) {
      return [];
    }
    return displayedEntitlements.map((item, index) => {
      return {
        rowClass: "entitlementsRow",
        onClick: null,
        columns: [
          {
            class: "",
            name: (
              <span style={{ display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon style={{ color: "#418172" }} icon={faCheck} />
                &nbsp;&nbsp;{item.name}
              </span>
            ),
          },
        ],
        actions: null,
      };
    });
  };

  const getAddOnsTableData = () => {
    if (!addOns || (addOns && addOns.length === 0)) {
      return [];
    }
    return addOns.map((item, index) => {
      return {
        rowClass: "addOnsRow",
        onClick: null,
        columns: [
          {
            class: "col-4",
            name: item.name,
          },
          {
            class: "",
            name: `${currencyUnit}${item.revenue} / ${billingUnit}ly`,
          },
          {
            class: "",
            name: (
              <ToggleOnOff
                initialValue={item.purchased}
                onToggle={() => {
                  let newAddOns = addOns.map((addOn) => {
                    if (addOn.id == item.id) {
                      let newAddOn = { ...addOn, purchased: !item.purchased };
                      let addOnHasBeenPreviouslyChanged = false;
                      let newChangedAddOns = changedAddOns.map(
                        (changeAddOn) => {
                          if (changeAddOn.id === addOn.id) {
                            addOnHasBeenPreviouslyChanged = true;
                            changeAddOn = newAddOn;
                          }
                          return changeAddOn;
                        }
                      );
                      if (addOnHasBeenPreviouslyChanged) {
                        setChangedAddOns(newChangedAddOns);
                      } else {
                        setChangedAddOns([...changedAddOns, newAddOn]);
                      }
                      return newAddOn;
                    } else {
                      return addOn;
                    }
                  });
                  setAddOns(newAddOns);
                }}
              />
            ),
          },
        ],
        actions: null,
      };
    });
  };

  const getHeaderData = (type) => {
    return {
      rowClass: "",
      columns: [
        {
          class: "",
          name: (
            <span style={{ textTransform: "capitalize" }}>
              {type == "entitlements" ? "Features" : "Available add-ons"}
            </span>
          ),
        },
      ],
    };
  };

  const getFooterData = () => {
    let content = (
      <div
        onClick={() => {
          if (displayedEntitlements.length <= 5) {
            setDisplayedEntitlements(entitlements);
          } else {
            setDisplayedEntitlements(entitlements.slice(0, 5));
          }
        }}
      >
        <p className="seeMoreEntitlementsButton">
          {displayedEntitlements.length <= 5 ? (
            <>
              <FontAwesomeIcon icon={faPlus} />{" "}
              <span>See More Entitlements </span>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faMinus} />{" "}
              <span>See Less Entitlements </span>{" "}
            </>
          )}
        </p>
      </div>
    );
    if (entitlements.length > 0) {
      return {
        rowClass: "col-12",
        columns: [{ class: "entitlementsFooter", content: content }],
      };
    } else return null;
  };

  const entitlementsTableData = getEntitlementsTableData();
  const footerData = getFooterData();

  let entitlementsTable = new ScopeStackTable(
    "entitlements",
    getHeaderData("entitlements"),
    entitlementsTableData,
    footerData
  );

  const addOnsTableData = getAddOnsTableData();

  let addOnsTable = new ScopeStackTable(
    "add ons",
    getHeaderData("add-ons"),
    addOnsTableData,
    null
  );

  const editPaymentMethods = (e) => {
    e.preventDefault();
    const cbPortal = cbInstance.createChargebeePortal();
    const callbacks = {};

    cbPortal.open(callbacks, {
      sectionType: window["Chargebee"].getPortalSections().EDIT_SUBSCRIPTION,
      params: {
        subscriptionId: "active_direct",
      },
    });
  };

  const cancelTrial = () => {
    if (freeTrials) {
      const activeTrial = freeTrials.filter(
        (trial) => trial.attributes.status === "active"
      )[0];

      const trialData = { data: { type: "free-trials", id: activeTrial.id } };

      API.Delete(
        `${apiHost}/${accountSlug}/v1/free-trials/${activeTrial.id}`,
        trialData,
        authorizationCode
      ).then((response) => {
        if (response.status === 204) {
          setSuccessMessage("Your trial has been canceled.");
          setShowSuccessAlert(true);
          setShowCancelTrialModal(false);
          window.location.reload();
        } else {
          setErrorMessages("Something went wrong.");
          setShowCancelTrialModal(false);
        }
      });
    }
  };

  return isLoading || subscriptionLoading || freeTrialsLoading ? (
    <SmallSpinner />
  ) : (
    <div>
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <ScopeStackModal
        modalTitle="Activate add-on"
        modalBody={
          <div className="regularText">
            <p>
              <strong>
                Are you sure you want to activate the SSO add-on for your
                account? This will result in additional charges.{" "}
              </strong>
            </p>
            <p>
              Current cost: {currencyUnit}
              {planPrice}
            </p>
            <p>
              New cost with add-on: {currencyUnit}
              {planPrice +
                parseFloat(
                  changedAddOns.length > 0
                    ? changedAddOns
                        .map((addOn) => addOn.revenue)
                        .reduce((a: number, b: number) => a + b)
                    : 0
                )}
            </p>
            <p>
              If you choose to activate, your card on file will be charged.{" "}
            </p>
          </div>
        }
        button1Text="Cancel"
        handleButton1Click={() => setShowAddOnModal(false)}
        button2Text="Activate add-on"
        handleButton2Click={(e) => saveAddOns(e)}
        show={showAddOnModal}
        handleClose={() => setShowAddOnModal(false)}
      />
      <ConfirmationModal
        show={showCancelTrialModal}
        title="Cancel Trial"
        message="Are you sure you want to cancel your free trial?"
        onConfirm={cancelTrial}
        onCancel={() => setShowCancelTrialModal(false)}
      />
      <Row>
        <Col sm={7}>
          <Card className="whiteBg">
            <Card.Header className="headerOverride">Plan</Card.Header>
            <hr className="cardHeadDivider" />
            <Card.Body className="whiteBg">
              {freeTrialActive ? (
                <Row>
                  <Col>
                    <div className="freeTrialDetails">
                      <strong>
                        {`${trialDaysRemaining} days remaining in your ${getPlanNameFromId(
                          freeTrialId
                        )} trial! `}
                      </strong>{" "}
                      <div className="ctaButtons">
                        <button
                          onClick={() =>
                            window.location.replace(
                              `${appHost}/upgrade#upgrade-now,${account?.attributes.currentTrial}`
                            )
                          }
                          className="upgradeNow"
                        >
                          <span>Upgrade Now</span>
                        </button>
                        <button
                          onClick={() => setShowCancelTrialModal(true)}
                          className="cancelTrial"
                        >
                          <span>Cancel Trial</span>
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : freeTrialAvailable ? (
                <Row>
                  <Col>
                    {nextPlanUp.cardTitle ? (
                      <div className="freeTrialOffer">
                        <h4 className="offerHeading">Limited time offer!</h4>
                        <span>
                          {`Demo the ${nextPlanUp.cardTitle} plan free for 1 month featuring:`}
                          {nextPlanUp.listItems &&
                            nextPlanUp.listItems
                              .slice(0, 2)
                              .map((item, index) => {
                                if (index == 1) {
                                  return (
                                    <span key={index}>
                                      <strong> {item.content}</strong>, and
                                      more!
                                    </span>
                                  );
                                } else {
                                  return (
                                    <span key={index}>
                                      <strong> {item.content}</strong>,
                                    </span>
                                  );
                                }
                              })}
                        </span>
                        <Button
                          type="button"
                          id="freeTrialBtn"
                          className="btnSeawhite"
                          variant="secondary"
                          onClick={(e) =>
                            window.location.replace(`${appHost}/upgrade`)
                          }
                        >
                          Claim your free trial
                        </Button>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              ) : null}

              <Row style={{ marginBottom: "20px" }}>
                <Col sm={6} className="currentPlan">
                  Current Plan:{" "}
                  {currentSubscription !== null &&
                  currentSubscription?.data.attributes["plan-id"] !== null
                    ? getPlanNameFromId(
                        currentSubscription?.data.attributes["plan-id"]
                      )
                    : "Free"}
                </Col>
                <Col sm={6} className="planPrice">
                  {currencyUnit}
                  {planPrice} / {billingUnit}{" "}
                  {addOns && addOns.length > 0 ? "with add-ons" : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <BaseTable
                    className="sansTable entitlementsTable"
                    striped={true}
                    hover={false}
                    bordered={true}
                    headerRows={entitlementsTable.buildHeaderRows()}
                    dataRows={entitlementsTable.buildDataRows()}
                    footerRows={entitlementsTable.buildFooterRows()}
                    isLoading={isLoading}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    style={{ float: "right" }}
                    type="button"
                    className="btnSeawhite"
                    variant="secondary"
                    onClick={(e) => editPaymentMethods(e)}
                  >
                    Edit payment methods
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={5}>
          <Card className="whiteBg">
            <Card.Header className="headerOverride">Add-ons</Card.Header>
            <hr className="cardHeadDivider" />
            <Card.Body className="whiteBg">
              <Row>
                <Col>
                  <BaseTable
                    className="sansTable addOnsTable"
                    striped={true}
                    hover={false}
                    bordered={true}
                    headerRows={addOnsTable.buildHeaderRows()}
                    dataRows={addOnsTable.buildDataRows()}
                    footerRows={addOnsTable.buildFooterRows()}
                    isLoading={isLoading}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="pricesAddendum">
                    *Prices will be pro-rated according to payment cycle up to
                    next renewal date.
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    type="button"
                    className="btnSeafoam"
                    variant="secondary"
                    onClick={() => setShowAddOnModal(true)}
                    disabled={changedAddOns.length === 0}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Billing;

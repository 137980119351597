import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { useListProjectVariablesQuery } from "@generated";
import { ProjectVariable } from "../../Admin/ProfessionalServices/common/types/ProjectVariable";
import { formatDate } from "@utils/helperFunctions";

const useListProjectVariables = (context) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null;

  const { data, error, isLoading, refetch, isFetching } =
    useListProjectVariablesQuery({
      slug: accountSlug,
      filter: {
        "variable-context": context,
        active: true,
      },
    });
  let allProjectVariables: ProjectVariable[];
  let requiredProjectVariables: ProjectVariable[] = [];
  let unrequiredProjectVariables: ProjectVariable[] = [];

  if (data?.data) {
    try {
      allProjectVariables = data.data.map((pv) => {
        if (!pv.id) {
          throw new Error("Missing pv.id");
        }
        if (!pv.attributes?.name) {
          throw new Error("Missing pv.attributes.name");
        }

        return {
          id: pv?.id.toString() || "",
          label: pv?.attributes?.label || "",
          required: pv?.attributes?.required || false,
          select_options: pv?.attributes?.select_options || [],
          "select-options": pv?.attributes?.["select-options"] || [],
          name: pv?.attributes?.name || "",
          "variable-type": pv?.attributes?.["variable-type"],
          variable_type: pv?.attributes?.["variable_type"],
          position: pv?.attributes?.position,
          maximum: pv?.attributes?.maximum,
          minimum: pv?.attributes?.minimum || 0,
          value:
            (pv?.attributes?.["variable-type"] === "date" || pv?.attributes?.["variable_type"] === "date")&& pv?.attributes?.required
              ? formatDate(new Date())
              : "",
        };
      });
      requiredProjectVariables = allProjectVariables.filter((variable) => {
        if (variable.required === true) {
          return variable.required;
        }
      });
      unrequiredProjectVariables = allProjectVariables.filter((variable) => {
        if (variable.required === false) {
          return variable;
        }
      });
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    allProjectVariables: requiredProjectVariables.concat(unrequiredProjectVariables),
    isLoading,
    error: error ?? customError,
    refetch,
    isFetching,
  };
};
export default useListProjectVariables;

import { faLoveseat } from "@fortawesome/pro-solid-svg-icons";
import {
  ListResourceRatesForAccountApiArg,
  V1ResourceRateResource,
  V1ResourceResource,
  useListResourceRatesForAccountQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";

export interface HookArgs {
  resources: V1ResourceResource[];
  filter: ListResourceRatesForAccountApiArg["filter"];
}

const useResourceRates = ({ resources = [], filter }: HookArgs) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const {
    data,
    error: resourceRatesError,
    isLoading: resourceRatesLoading,
    refetch: resourceRatesRefetch,
  } = useListResourceRatesForAccountQuery({
    slug: accountSlug,
    page: { size: 1000 },
    include: ["line-of-business"],
    filter,
  });

  let resourceRates: V1ResourceRateResource[] = [];
  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  if (data?.data) {
    try {
      resourceRates = data?.data;

      if (!data?.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data?.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data?.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data?.meta["record-count"],
        pageCount: data?.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    resourceRates,
    resourceRatesError: resourceRatesError || customError,
    resourceRatesLoading,
    pageMeta,
    resourceRatesRefetch: resourceRatesRefetch,
  };
};

export default useResourceRates;
import React, { ReactNode, useEffect, useState } from "react";
import Select, { Props as SelectProps } from "react-select";
import { Form } from "react-bootstrap";
import API from "../../../utils/API/API";
import useOauth from "@utils/customHooks/useOauth";
const errorMessage = require("../../../images/error-message-white.png");

const DropdownApiJs = ({
  value,
  onChange,
  onInputChange,
  label,
  className,
  width = "100%",
  placeholder,
  required,
  defaultValue,
  isInvalid,
  onFocus,
  isDisabled = false,
  url,
}) => {
  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState([]);
  const [authorizationCode] = useOauth();

  useEffect(() => {
    if (authorizationCode !== "") {
      API.Get(
        url + `?filter[name]=${encodeURIComponent(searchText)}`,
        authorizationCode
      ).then((res) => {
        setOptions(
          res.data.data.map((item) => {
            return {
              label: item.attributes.name,
              value: item.id,
            };
          })
        );
      });
    }
  }, [searchText, authorizationCode]);

  const onInputChangeFunction = (e) => {
    if (e?.length > 2) {
      setSearchText(e);
    }
    if (onInputChange) {
      onInputChange(e.target.value, {
        action: "set-value",
        prevInputValue: "",
      });
    }
  };

  return (
    <Form.Group
      className="formGroup"
      style={isInvalid ? { marginBottom: "0px" } : {}}
    >
      <Form.Label>{label}</Form.Label>
      <Select
        className={className}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onInputChange={(e) => onInputChangeFunction(e)}
        options={options}
        isMulti={false}
        required={required}
        onFocus={onFocus}
        isDisabled={isDisabled}
      />
      {required && isInvalid ? (
        <div>
          <img
            src={errorMessage}
            alt="Please fill out this field error message"
          />
        </div>
      ) : null}
    </Form.Group>
  );
};

export default DropdownApiJs;

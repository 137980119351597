import React, { useState, useEffect } from "react";
import DataTable from "@components/DataTable";
import {
  useAcceptVendorDocumentForQuoteMutation,
  useCreateQuoteMutation,
  useDeleteQuoteMutation,
  useListServiceCategorysForAccountQuery,
  useListVendorsForAccountQuery,
} from "@generated";
import CurrencyFormat from "react-currency-format";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faPencil,
  faRefresh,
} from "@fortawesome/pro-solid-svg-icons";
import { ColumnDef } from "@tanstack/react-table";
import { Card, Row, Col, Button } from "react-bootstrap";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import "./styles/style.css";
import { useLocation, useNavigate } from "react-router";
import { handleAlert, snakeToText, truncateString } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import useListQuotes from "./api/useListQuotes";
import { OptionType, Quote } from "../types/Quote";
import { faEye } from "@fortawesome/pro-solid-svg-icons";
import useFlags from "@common/hooks/useFlags";
import RequiredReactSelect from "@components/FormsV2/RequiredReactSelect/RequiredReactSelect";
import { FileUploadDragAndDrop } from "@components/FormsV2/FileUploadDragAndDrop";
import fileToBase64 from "@utils/fileToBase64";
import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import DescriptionSlideout from "@components/SlideOutMenuV2";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";

const Show = ({ project_id, account, permission, project, revenueCalculationLabel }): JSX.Element => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const [selectedQuoteIds, setSelectedQuoteIds] = useState<string[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessages, setErrorMessages] = useState<string | string[]>(
    "Something went wrong! Your changes could not be saved at this time."
  );
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    "Vendor Quote saved successfully!"
  );
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [reloading, setReloading] = useState(false);

  // Bulk Delete States
  const [deleteQuote] = useDeleteQuoteMutation();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteQueue, setDeleteQueue] = useState<string[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const { vendorQuotesV2Import } = useFlags();

  const [vendorName, setVendorName] = useState<OptionType>({
    value: 0,
    label: "Search and select vendor name",
  });
  const [vendorNameInvalid, setVendorNameInvalid] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [createVendorQuote] = useCreateQuoteMutation();
  const [showImportSuccessModal, setShowImportSuccessModal] = useState(false);
  const [acceptVendorDocument] = useAcceptVendorDocumentForQuoteMutation();
  const [documentProcessing, setDocumentProcessing] = useState(false);
  const [slideoutOpen, setSlideoutOpen] = useState(false);
  const [descriptionPreview, setDescriptionPreview] = useState("");

  const billingFrequencies: OptionType[] = [
    "one_time",
    "monthly",
    "quarterly",
    "yearly",
  ].map((bf) => {
    return { value: bf, label: snakeToText(bf) || "" } as OptionType;
  });

  const {
    data: lobs,
    isLoading: lobsLoading,
    isError: lobsErr,
  } = useListServiceCategorysForAccountQuery({ slug: accountSlug });

  const {
    data: vendors,
    isLoading: vendorsLoading,
    isError: vendorsErr,
    refetch: refetchVendors,
  } = useListVendorsForAccountQuery({ slug: accountSlug });

  const { quotes, quotesError, quotesLoading, pageMeta, refetch } =
    useListQuotes({
      page: {
        number: pageNumber,
        size: pageSize,
      },
      include: ["vendor", "lob"],
      filter: { project: project_id },
      billingFrequencies: billingFrequencies
    });

  useEffect(() => {
    window.addEventListener(
      "dragover",
      function (e) {
        e = e || event;
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      function (e) {
        e = e || event;
        e.preventDefault();
      },
      false
    );
    window.history.replaceState({}, "");
    setReloading(true);
    refetch().then((res) => setReloading(false));
  }, []);

  const handleDelete = () => {
    setDeleteQueue([...selectedQuoteIds]);
    setShowConfirmationModal(true);
  };

  const confirmDelete = () => {
    setIsDeleting(true);
    if (deleteQueue.length === 0) return;

    const promises: any = [];
    deleteQueue.forEach((quoteIdToDelete) => {
      promises.push(
        deleteQuote({
          slug: accountSlug,
          id: parseInt(quoteIdToDelete),
        })
      );
    });

    Promise.all(promises)
      .then((res) => {
        setSuccessMessage(
          `Vendor Quote${
            deleteQueue.length > 1 ? "s" : ""
          } deleted successfully!`
        );
        setShowSuccessAlert(true);
        setIsDeleting(false);
        setShowConfirmationModal(false);
        setSelectedQuoteIds([]);
        setDeleteQueue([]);
        refetch();
      })
      .catch((error) => {
        setErrorMessages(
          "One or more quotes could not be deleted at this time. Please try again later."
        );
        setShowFailAlert(true);
      });
  };

  const handleConfirm = () => {
    confirmDelete();
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
    setDeleteQueue([]);
  };

  const getConfirmationMessage = () => {
    return (
      <>
        <div
          style={{
            color: "#1C2655",
            fontSize: "14px",
          }}
        >
          {isDeleting ? (
            <SmallSpinner />
          ) : (
            <span style={{ fontWeight: "700" }}>{`You are about to delete ${
              selectedQuoteIds.length
            } Vendor Quote${selectedQuoteIds.length > 1 ? "s" : ""}.`}</span>
          )}
          <ul style={{ marginTop: "10px" }}>
            {selectedQuoteIds.map((quoteIdToDelete) => {
              let quoteName = quotes.find(
                (quote) => quote.id == quoteIdToDelete
              )?.vendor.label;
              return <li>{quoteName}</li>;
            })}
          </ul>
          This action cannot be undone. Do you wish to proceed?
        </div>
      </>
    );
  };

  const formatCurrency = (price) => {
    if (price) {
      return (
        <CurrencyFormat
          displayType="text"
          prefix={
            project?.data?.attributes?.["field-labels"]["currency_unit"]
              ? project.data.attributes["field-labels"]["currency_unit"]
              : "$"
          }
          isNumericString={true}
          thousandSeparator={true}
          value={(Number(price) || 0).toFixed(2)}
        />
      );
    } else {
      return "";
    }
  };

  let quoteColumns: ColumnDef<Quote>[] = [
    {
      header: "Vendor",
      cell(props) {
        return (
          <div>
            {props.row.original.vendor.label}{" "}
            {props.row.original.id !== 0 && (
              <FontAwesomeIcon
                className="cursorPoint"
                icon={faPencil}
                onClick={() => {
                  if (props.row.original.vendorDocumentStatus === "processed") {
                    acceptVendorDocument({
                      slug: accountSlug,
                      id: Number(props.row.original.id),
                    })
                      .unwrap()
                      .then()
                      .catch((err) => {
                        let msg = err?.data?.errors?.[0]?.detail;
                        setErrorMessages(
                          msg
                            ? msg
                            : "Something went wrong. Your changes could not be saved at this time."
                        );
                        setShowFailAlert(true);
                      });
                  }
                  navigate(
                    `/projects/${project_id}/quotes/edit/${props.row.original.id}`,
                    {
                      state: { quote: props.row.original},
                    }
                  );
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      header: "Description",
      cell(props) {
        let docStatus = props.row.original.vendorDocumentStatus;
        let description = props.row.original.description || "";
        const processingDescription =
          "We are actively processing this document. Please check back in a few minutes!";
        if (props.row.original.documentPending) {
          switch (docStatus) {
            case "processed":
              description = "Quote processed successfully, awaiting review";
              break;
            case "error":
              description =
                "An error occurred. Document unable to be processed at this time.";
              break;
            case "pending":
              description = processingDescription;
              break;
            case "submitted":
              description = processingDescription;
              break;
            default:
              description = description;
          }
          return (
            <div
              className="fullColSpanColumnContent"
              style={{ display: "flex", alignItems: "center" }}
            >
              <FontAwesomeIcon
                className="cursorPoint"
                style={
                  docStatus === "processed"
                    ? { color: "#418172", marginRight: "10px" }
                    : docStatus === "error"
                    ? { color: "#CC672F", marginRight: "10px" }
                    : { color: "#FCD22E", marginRight: "10px" }
                }
                icon={faCircle}
              />
              <span
                style={
                  docStatus === "processed"
                    ? { color: "#418172" }
                    : docStatus === "error"
                    ? { color: "#CC672F" }
                    : {}
                }
              >
                {description}
              </span>
            </div>
          );
        }
        return description.length > 20 ? (
          <>
            <div>{truncateString(description, 20, null)}</div>
            {props.row.original.id !== 0 && (
              <FontAwesomeIcon
                className="descriptionPreview"
                icon={faEye}
                onClick={() => {
                  setDescriptionPreview(description);
                  setSlideoutOpen(true);
                }}
              />
            )}
          </>
        ) : (
          description
        );
      },
    },
    {
      header: "One Time Cost",
      cell(props) {
        return props.row.original.documentPending ? (
          <div className="hideForFullColspan"></div>
        ) : (
          formatCurrency(props.row.original.oneTimeCost)
        );
      },
    },
    {
      header: "Recurring Cost",
      cell(props) {
        return props.row.original.documentPending ? (
          <div className="hideForFullColspan"></div>
        ) : (
          formatCurrency(props.row.original.recurringCost)
        );
      },
    },
    {
      header: snakeToText(revenueCalculationLabel),
      cell(props) {
        return props.row.original.documentPending ? (
          <div className="hideForFullColspan"></div>
        ) : props?.row?.original?.markup ? (
          Number(props?.row?.original?.markup).toFixed(2) + "%"
        ) : (
          ""
        );
      },
    },
    {
      header: "One Time Revenue",
      cell(props) {
        return props.row.original.documentPending ? (
          <div className="hideForFullColspan"></div>
        ) : (
          formatCurrency(props.row.original.oneTimeRevenue)
        );
      },
    },
    {
      header: "Recurring Revenue",
      cell(props) {
        return props.row.original.documentPending ? (
          <div className="hideForFullColspan"></div>
        ) : (
          formatCurrency(props.row.original.recurringRevenue)
        );
      },
    },
  ];

  if (permission === "none") {
    return (
      <div className="quotePage">
        You do not have permmission to view this page. Please contact your
        administrator for assistance.
      </div>
    );
  }

  const vendorOptions: any = (vendors?.data || []).map((vendor) => {
    return {
      label: vendor?.attributes?.["name"] || "",
      value: vendor?.id?.toString() || "",
    };
  });

  const handleFileSelect = (file: File) => {
    if (!file.type.includes("pdf")) {
      setErrorMessages("The uploaded file must be in pdf format.");
      setShowFailAlert(true);
    } else if (!(file.size > 5242880)) {
      setUploadedFile(file);
    }
  };

  const processQuote = async () => {
    setDocumentProcessing(true);
    if (uploadedFile) {
      const encodedFile = await fileToBase64(uploadedFile);

      let quoteData: any = {
        type: "quotes",
        attributes: {
          "vendor-document": encodedFile,
        },
        relationships: {
          project: { data: { type: "projects", id: project_id } },
          vendor: { data: { type: "vendors", id: vendorName.value } },
        },
      };

      if (!account || !account.data?.attributes?.slug) return;

      if (encodedFile) {
        createVendorQuote({
          slug: accountSlug,
          body: { data: quoteData },
        })
          .unwrap()
          .then((response) => {
            if (response) {
              handleImportModalClose();
              setShowImportSuccessModal(true);
              setDocumentProcessing(false);
            }
          })
          .catch((err) => {
            let msg = err?.data?.errors?.[0]?.detail;
            setErrorMessages(
              msg
                ? msg
                : "Something went wrong. Your changes could not be saved at this time."
            );
            setShowFailAlert(true);
            setDocumentProcessing(false);
          });
      }
    }
  };

  const handleImportSuccessModalClose = () => {
    setShowImportSuccessModal(false);
    refetch();
  };

  const handleImportModalClose = () => {
    setShowImportModal(false);
    setUploadedFile(null);
    setVendorName({
      value: 0,
      label: "Search and select vendor name",
    });
  };

  return (
    <div className="quotePage">
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <DescriptionSlideout
        title="Vendor Quote Description"
        isOpen={slideoutOpen}
        onClose={() => setSlideoutOpen(false)}
      >
        <FormFieldLabel label="Description" className="" />
        <div className="productDescription">{descriptionPreview || "N/A"}</div>
      </DescriptionSlideout>
      <Card>
        <Card.Header
          style={{
            backgroundColor: "#fff",
            color: "#1C2655",
            padding: "0px 16px",
          }}
        >
          <Row
            style={{
              padding: "20px 0 0 20px",
              alignItems: "center",
            }}
          >
            <Col className="text24" sm={7}>
              Vendor Quotes
            </Col>
            <Col>
              {permission !== "view" &&
                project?.data?.attributes?.status === "building" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      float: "right",
                    }}
                  >
                    {vendorQuotesV2Import && (
                      <Button
                        className="btnSeawhite"
                        onClick={() => setShowImportModal(true)}
                        style={{ marginLeft: "" }}
                      >
                        Import
                      </Button>
                    )}
                    <Button
                      className="squareGreenButton"
                      onClick={() => {
                        navigate(`/projects/${project_id}/quotes/new`, {
                          state: {},
                        });
                      }}
                      style={{ marginRight: "16px", marginLeft: "10px" }}
                    >
                      Add Vendor Quote
                    </Button>
                  </div>
                )}
            </Col>
          </Row>
        </Card.Header>
        <hr style={{ marginLeft: "30px", marginRight: "30px" }} />
        <Card.Body style={{ marginTop: "-10px" }}>
          {selectedQuoteIds.length > 0 ? (
            <Button className="deleteBox" onClick={handleDelete}>
              {selectedQuoteIds.length} Selected{" "}
              <span className="selectedSpan"></span>
              <FontAwesomeIcon style={{ color: "black" }} icon={faTrashCan} />
            </Button>
          ) : (
            <div className="multiDeletePlaceholder"></div>
          )}
          {!quotes || quotesLoading || !lobs || lobsLoading || reloading ? (
            <SmallSpinner />
          ) : (
            <DataTable
              classForTable="quoteTable"
              data={
                quotes?.length > 0
                  ? quotes
                  : [
                      {
                        id: 0,
                        vendor: {
                          label: "Add quotes to get started",
                          value: 0,
                        },
                      },
                    ]
              }
              columns={quoteColumns}
              selectable={
                quotes.length > 0 &&
                permission !== "view" &&
                project?.data?.attributes?.status === "building"
              }
              striped
              hover
              selectedIds={selectedQuoteIds}
              setSelectedIds={setSelectedQuoteIds}
              totalPages={pageMeta.pageCount}
              totalRows={pageMeta.recordCount}
              currentPage={pageNumber}
              setCurrentPage={setPageNumber}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
              paginationEnabled
              pageSizeEnabled
            />
          )}
          {quotes.length > 0 && (
            <div style={{ marginLeft: "16px" }} className="text20">
              <strong>Subtotal:</strong>&nbsp;
              {formatCurrency(
                quotes
                  .map((e) => Number(e.totalRevenue || 0))
                  .reduce((acc, current) => acc + current, 0)
              )}
            </div>
          )}
          <ScopeStackModal
            modalTitle="Delete Vendor Quote"
            modalBody={getConfirmationMessage()}
            handleButton2Click={isDeleting ? () => null : handleConfirm}
            handleClose={handleCancel}
            show={showConfirmationModal}
            handleButton1Click={handleCancel}
            deleteModal={true}
            button1Text={"Cancel"}
            button2Text={"Delete"}
          />
          <ScopeStackModal
            modalTitle="Import A Vendor Quote"
            modalBody={
              documentProcessing ? (
                <SmallSpinner />
              ) : (
                <div className="regularText">
                  <p>
                    <strong>How to use: </strong>
                  </p>
                  <p className="regularText">
                    Choose vendor then upload a vendor quote to get started and
                    then your document will be processed by our system.
                  </p>
                  <p>
                    <Row style={{ alignItems: "center" }}>
                      <Col sm={9}>
                        <RequiredReactSelect
                          label="* Vendor Name"
                          value={vendorName}
                          required={true}
                          isInvalid={vendorNameInvalid}
                          onChange={(e) => {
                            const vendorName = e as OptionType;
                            setVendorName(vendorName);
                          }}
                          options={vendorOptions}
                          isDisabled={false}
                          onFocus={refetchVendors}
                        />
                      </Col>
                      <Col>
                        <Button
                          style={{ marginLeft: "35px" }}
                          className="btnSeawhite"
                          onClick={() =>
                            window.open(
                              `${appHost}/admin/vendors/new`,
                              "_blank"
                            )
                          }
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                  </p>
                  <p>
                    <label className="labelLike">
                      {uploadedFile
                        ? "Uploaded Vendor Quote"
                        : "* Upload A Vendor Quote (PDF)"}
                    </label>
                    {uploadedFile ? (
                      <div>
                        <span>
                          {uploadedFile.name}{" "}
                          <FontAwesomeIcon
                            style={{
                              color: "red",
                              cursor: "pointer",
                              marginLeft: "5px",
                            }}
                            icon={faTrashCan}
                            onClick={() => setUploadedFile(null)}
                          />
                        </span>
                      </div>
                    ) : (
                      <FileUploadDragAndDrop
                        handleFileSelect={handleFileSelect}
                      />
                    )}
                  </p>
                </div>
              )
            }
            button1Text="Cancel"
            handleButton1Click={handleImportModalClose}
            button2Text={uploadedFile ? "Process Quote" : null}
            handleButton2Click={uploadedFile ? processQuote : null}
            button2Disabled={vendorName.value === 0 || documentProcessing}
            show={showImportModal}
            handleClose={handleImportModalClose}
          />
          <ScopeStackModal
            modalTitle="Import Success"
            modalBody={
              <div>
                <p
                  style={{ display: "flex", alignItems: "center" }}
                  className="instructionalText"
                >
                  <Row className="bold">
                    <Col sm={2}>
                      <FontAwesomeIcon
                        style={{
                          color: "#FCCE2B",
                          borderRadius: "100px",
                          border: "2px solid #FCCE2B",
                          padding: "10px",
                          marginRight: "10px",
                        }}
                        icon={faRefresh}
                      />
                    </Col>
                    <Col>
                      Vendor Quote uploaded successfully and processing!
                    </Col>
                  </Row>
                </p>
                <p className="regularText">
                  This can take a few minutes depending on the complexity of
                  your document. Please check back on the Vendor Quotes page to
                  see the status of this upload.
                </p>
              </div>
            }
            button1Text="Close"
            handleButton1Click={handleImportSuccessModalClose}
            button2Text={null}
            handleButton2Click={null}
            show={showImportSuccessModal}
            handleClose={handleImportSuccessModalClose}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default Show;

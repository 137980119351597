import { useGetAccountConnectionQuery } from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { AccountConnection } from "../../types/connection";

const useGetConnectionById = (id) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading, isFetching, refetch } =
    useGetAccountConnectionQuery({
      slug: accountSlug,
      id: Number(id),
    });

  let connection: AccountConnection | undefined = undefined;
  let customError: string | null = null;

  if (data?.data) {
    try {
      if (!data.data.id) {
        throw new Error("Missing connection.id");
      }

      const isFileStorage = data?.data?.attributes?.service === "merge_dev";
      connection = {
        id: Number(data.data.id),
        active: data.data?.attributes?.active || false,
        service: data.data?.attributes?.service || "",
        host: isFileStorage
          ? //@ts-ignore
            data?.data?.attributes?.settings?.storage || ""
          : data?.data?.attributes?.host || "",
        status: data.data?.attributes?.status || "",
        lastSync: data.data?.attributes?.["last-sync-at"] || "",
        statusInfo: data.data?.attributes?.["status-information"] || "",
        settings: data.data?.attributes?.settings,
        credentials: data.data?.attributes?.credentials,
        mappings: data.data?.attributes?.mappings,
        crm: data.data?.attributes?.crm || false,
        psa: data.data?.attributes?.psa || false,
        product: data.data?.attributes?.product || false,
        esignature: data.data?.attributes?.esignature || false,
        "file-storage": data.data?.attributes?.["file-storage"] || false,
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    connection,
    error: error || customError,
    connectionLoading: isLoading,
    connectionFetching: isFetching,
    refetch,
  };
};

export default useGetConnectionById;

import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import EditPaymentCredit from "./EditPaymentCredit/EditPaymentCredit";
import ProjectPaymentCredits from "./ProjectPaymentCredits/ProjectPaymentCredits";
import NewPaymentCredit from "./NewPaymentCredit/NewPaymentCredit";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";

function PaymentCreditsMspa({ account_slug, project_id, account_id }) {
  const [creditId, setCreditId] = useState(null);
  const [projectStatus, setProjectStatus] = useState("");
  const [projectCredits, setProjectCredits] = useState([]);
  const [paymentCredits, setPaymentCredits] = useState([]);
  const [paymentCreditCreated, setPaymentCreditCreated] = useState(false);
  const [paymentCreditUpdated, setPaymentCreditUpdated] = useState(false);
  const [currencyUnit, setCurrencyUnit] = useState("");
  const [permission, setPermission] = useState("none");

  const [authorizationCode] = useOauth();

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (authorizationCode != "") {
      // Get User Permissions
      API.Get(`${apiHost}/v1/me`, authorizationCode).then((response) => {
        let data = response.data;
        if (data) {
          const privilege = data.data.attributes.privileges.find(
            (p) => p.privilege === "projects.payment_credits"
          );
          if (privilege) setPermission(privilege["access-level"]);
        }
      });

      // Get Project Data & set Project Status
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}?include=rate-table`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        if (data.attributes.status !== null)
          setProjectStatus(data.attributes.status);
        setCurrencyUnit(data.attributes["field-labels"].currency_unit);
        setIsLoading(false);
      });

      // Get Project Credits
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-credits?include=payment-credit&filter[active]=true`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        setProjectCredits(data);
      });

      // Get Payment Credits
      API.Get(
        `${apiHost}/${account_slug}/v1/payment-credits/`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        setPaymentCredits(data);
      });
    }
  }, [authorizationCode]);

  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/projects/:project_id/project_credits"}
            element={
              <ProjectPaymentCredits
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                creditId={creditId}
                setCreditId={(creditId) => setCreditId(creditId)}
                projectStatus={projectStatus}
                projectCredits={projectCredits}
                paymentCreditCreated={paymentCreditCreated}
                paymentCreditUpdated={paymentCreditUpdated}
                currencyUnit={currencyUnit}
                isLoading={isLoading}
                paymentCredits={paymentCredits}
                authorizationCode={authorizationCode}
                permission={permission}
              />
            }
          />
          <Route
            path={"/projects/:project_id/project_credits/new"}
            element={
              <NewPaymentCredit
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                projectCredits={projectCredits}
                setPaymentCreditCreated={(created) =>
                  setPaymentCreditCreated(created)
                }
                paymentCreditCreated={paymentCreditCreated}
                paymentCreditUpdated={paymentCreditUpdated}
                currencyUnit={currencyUnit}
                paymentCredits={paymentCredits}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={"/projects/:project_id/project_credits/:creditId/edit"}
            element={
              <EditPaymentCredit
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                projectCredits={projectCredits}
                creditId={creditId}
                setPaymentCreditUpdated={(updated) =>
                  setPaymentCreditUpdated(updated)
                }
                paymentCreditCreated={paymentCreditCreated}
                paymentCreditUpdated={paymentCreditUpdated}
                currencyUnit={currencyUnit}
                paymentCredits={paymentCredits}
                authorizationCode={authorizationCode}
                permission={permission}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default PaymentCreditsMspa;

import React from "react";
import PaymentCredit from "../PaymentCredit/PaymentCredit";

function EditPaymentCredit({
  account_slug,
  project_id,
  account_id,
  projectStatus,
  projectCredits,
  creditId,
  setPaymentCreditUpdated,
  paymentCreditCreated,
  paymentCreditUpdated,
  currencyUnit,
  paymentCredits,
  authorizationCode,
  permission,
}) {
  return (
    <PaymentCredit
      account_slug={account_slug}
      project_id={project_id}
      account_id={account_id}
      projectStatus={projectStatus}
      projectCredits={projectCredits}
      creditId={creditId}
      setPaymentCreditUpdated={setPaymentCreditUpdated}
      paymentCreditCreated={paymentCreditCreated}
      paymentCreditUpdated={paymentCreditUpdated}
      currencyUnit={currencyUnit}
      paymentCredits={paymentCredits}
      authorizationCode={authorizationCode}
      permission={permission}
    />
  );
}

export default EditPaymentCredit;

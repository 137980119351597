import { capitalizeFirstLetter, snakeToText } from "@utils/helperFunctions";

export const normalizeCasing = (string) =>
  ["crm", "psa"].includes(string)
    ? string.toUpperCase()
    : string === "file-storage"
    ? "File Storage"
    : capitalizeFirstLetter(string);

export const normalizeAppName = (name) => {
  switch (name) {
    case "salesforce":
      return "Salesforce";
    case "connectwise":
      return "ConnectWise";
    case "autotask":
      return "Autotask";
    case "microsoft_dynamics":
      return "Microsoft Dynamics";
    case "docusign":
      return "DocuSign";
    case "halopsa":
      return "HaloPSA";
    case "hubspot":
      return "HubSpot";
    case "merge_dev":
      return "File Storage";
    case "dropbox":
      return "Dropbox";
    case "onedrive":
      return "One Drive";
    case "googledrive":
      return "Google Drive";
    case "sharepoint":
      return "SharePoint";
    default:
      return name;
  }
};

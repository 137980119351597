import React, { ChangeEvent } from "react";
import { Form } from "react-bootstrap";

interface MultipleLineFieldV2Props {
  label: string;
  id: string;
  placeholder?: string;
  value?: string | null | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  updateRef?: boolean;
  className?: string;
  rows?: number;
  defaultValue?: string;
  onBlur?: (e?: any) => void;
}

const MultipleLineFieldV2: React.FC<MultipleLineFieldV2Props> = ({
  id,
  label,
  rows,
  placeholder,
  value,
  defaultValue,
  onChange,
  onBlur,
  required,
  disabled = false,
  readOnly = false,
}) => {
  return (
    <Form.Group className="mb-3 formGroup">
      <Form.Label>{label}</Form.Label>
      <Form.Control
        required={required}
        id={id}
        as="textarea"
        rows={rows}
        value={value === null ? "" : value}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
      />
    </Form.Group>
  );
};

export default MultipleLineFieldV2;

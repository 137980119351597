import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import FormNumberField from "@components/Forms/FormNumberField";
import MultipleLineField from "@components/Forms/MulitpleLineField";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import BackButton from "@components/Buttons/BackButton/BackButton";
import { useNavigate } from "react-router-dom";
import API from "@API";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";

function PaymentCredit({
  account_slug,
  project_id,
  account_id,
  projectStatus,
  projectCredits,
  setPaymentCreditCreated,
  paymentCreditCreated,
  paymentCreditUpdated,
  currencyUnit,
  paymentCredits,
  authorizationCode,
  permission,
}) {
  const [paymentCredit, setPaymentCredit] = useState("Please select");
  const [newPaymentCreditId, setNewPaymentCreditId] = useState(null);
  const [amount, setAmount] = useState("0.0");
  const [criteria, setCriteria] = useState("");

  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [successAlertIsOpen, setSuccessAlertIsOpen] = useState(false);

  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  let navigate = useNavigate();

  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  useEffect(() => {
    if (lastWordInLocation === "edit") {
      projectCredits.forEach((projectCredit) => {
        if (projectCredit.id == id) {
          let paymentCreditData =
            projectCredit.relationships["payment-credit"].data;
          paymentCredits.forEach((paymentCredit) => {
            if (paymentCreditData && paymentCredit.id == paymentCreditData.id) {
              setPaymentCredit(
                `${paymentCredit.attributes["payment-source"]} / ${paymentCredit.attributes.description}`
              );
              setNewPaymentCreditId(paymentCredit.id);
            }
          });

          setAmount(projectCredit.attributes.amount);
          setCriteria(projectCredit.attributes.criteria);
        }
      });
    }
  }, [projectCredits, paymentCredits]);

  const paymentCreditOptions = paymentCredits.map((paymentCredit) => (
    <option accessKey={paymentCredit.id} key={paymentCredit.id}>
      {`${paymentCredit.attributes["payment-source"]} / ${paymentCredit.attributes.description}`}
    </option>
  ));

  const redirectToEditPayemntCredit = (creditId) => {
    navigate(`/projects/${project_id}/project_credits/${creditId}/edit`);
    window.location.reload();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let attributes = {
      amount: amount,
    };

    let relationships = {
      project: { data: { type: "projects", id: project_id } },
      "payment-credit": {
        data: { type: "payment-credits", id: newPaymentCreditId },
      },
    };

    // Post new credit
    if (lastWordInLocation === "new") {
      const creditData = {
        data: {
          type: "project-credits",
          attributes: attributes,
          relationships: relationships,
        },
      };

      API.Post(
        `${apiHost}/${account_slug}/v1/project-credits`,
        creditData,
        authorizationCode
      )
        .then((response) => {
          let id = response.data.data.id;
          setPaymentCreditCreated(true);
          redirectToEditPayemntCredit(id);
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "Payment Credit"));
          setShowFailAlert(true);
        });
    }

    // Update Credit
    if (lastWordInLocation === "edit") {
      const updatedCreditData = {
        data: {
          type: "project-credits",
          id: id,
          attributes: attributes,
          relationships: relationships,
        },
      };

      API.Patch(
        `${apiHost}/${account_slug}/v1/project-credits/${id}`,
        updatedCreditData,
        authorizationCode
      )
        .then((response) => {
          setSuccessAlertIsOpen(true);
          setTimeout(() => {
            setSuccessAlertIsOpen(false);
          }, 3000);
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "Payment Credit"));
          setShowFailAlert(true);
        });
    }
  };

  const handleSuccessAlert = () => {
    if (successAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="Alerts"
          variant="info"
          onClose={() => setSuccessAlertIsOpen(false)}
          text="Payment credit updated"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {handleFailAlert()}
      {handleSuccessAlert()}
      <Card>
        <CardHeader title="Payment Credit" />
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <ControlledDropDown
                  label="Payment Credit *"
                  required={true}
                  options={
                    lastWordInLocation !== "new"
                      ? paymentCreditOptions
                      : [
                          <option accessKey="blank" value="" key="blank">
                            Please select
                          </option>,
                          paymentCreditOptions,
                        ]
                  }
                  value={paymentCredit}
                  onChange={(e) => {
                    setPaymentCredit(e.target.value);
                    let selected = e.target.options.selectedIndex;
                    let selectedId = e.target.options[selected].accessKey;
                    setNewPaymentCreditId(selectedId);
                    paymentCredits.forEach((paymentCredit) => {
                      if (paymentCredit.id == selectedId) {
                        setCriteria(paymentCredit.attributes.criteria);
                      }
                    });
                  }}
                  disabled={
                    lastWordInLocation === "edit" && permission !== "manage"
                  }
                />
              </Col>
              <Col>
                <FormNumberField
                  label="Amount *"
                  required={true}
                  step="1.0"
                  min="0.0"
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                  disabled={
                    lastWordInLocation === "edit" && permission !== "manage"
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <MultipleLineField
                  rows={5}
                  label="Criteria"
                  value={criteria}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                {(lastWordInLocation === "new" ||
                  (lastWordInLocation === "edit" &&
                    permission === "manage")) && <SubmitButton />}
              </Col>
              <Col xs={6}>
                <BackButton url={`/projects/${project_id}/project_credits`} />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default PaymentCredit;
